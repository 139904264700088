import React, { useState } from "react";
import Input from "./Input";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
const PasswordInput = ({
  name,
  placeholder,
  label,
  value,
  handleOnChange,
  isRequired,
  props,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{ position: "relative" }}>
      <Input
        type={showPassword ? "text" : "password"}
        label={label}
        placeholder={placeholder}
        value={value}
        handleOnChange={handleOnChange}
        isRequired={isRequired}
        name={name}
        props={props}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        style={{
          position: "absolute",
          right: "20px",
          top: "65%",
          transform: "translateY(-50%)",
          border: "none",
          background: "none",
          cursor: "pointer",
          padding: 0,
          margin: 0,
          width: 20,
          color: "black",
          fontSize: 24,
        }}
      >
        {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
      </button>
    </div>
  );
};

export default PasswordInput;
