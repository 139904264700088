import React, { useEffect, useRef, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "../../styles/NotesList.css";
import { VscKebabVertical } from "react-icons/vsc";
import { TbEye } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import useRecordingStore from "../../utils/recordingStore";
import { MdArrowBack } from "react-icons/md";
import axios from "axios";
import useUserStore from "../../utils/userStore";

const getTimestampsForSelectedDate = (selectedDate) => {
  // Create a Date object for the start of the selected date
  const startDate = new Date(selectedDate);
  startDate.setHours(0, 0, 0, 0);

  // Create a Date object for the start of the next day
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 1);

  return {
    startTimestamp: startDate,
    endTimestamp: endDate,
  };
};

const getDateFromSeconds = (seconds) => {};

const NotesList = ({ selectedRecord, setSelectedRecord, reloadData }) => {
  const [notes, setNotes] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const searchName = useRecordingStore((state) => state.searchName);
  const setSearchName = useRecordingStore((state) => state.setSearchName);
  const [recordings, setRecordings] = useState([]);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const selectedDate = useRecordingStore((state) => state.selectedDate);
  const setSelectedDate = useRecordingStore((state) => state.setSelectedDate);
  const dropdownRef = useRef(null);
  const [recordingsDict, setRecordingsDict] = useState({});
  const { startTimestamp, endTimestamp } =
    getTimestampsForSelectedDate(selectedDate);
  const [filteredRecordings, setFilteredRecordings] = useState([]);
  const currentUser = useUserStore((state) => state.currentUser);

  useEffect(() => {
    const fetchRecordings = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/get-recordings`,
        {
          user_id: user.uid,

          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );
      const data = response.data;

      if (searchName) {
        setRecordings(
          data.filter((doc) =>
            doc.patient_name.toLowerCase().includes(searchName.toLowerCase())
          )
        );
      } else {
        setRecordings(data);
      }
    };

    fetchRecordings();
  }, [reloadData]);

  useEffect(() => {
    // Function to convert seconds to Date object and format date as string "YYYY-MM-DD"
    const getDateKey = (seconds) => {
      const milliseconds = seconds * 1000;
      const date = new Date(milliseconds);
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    };

    // Populate the recordings dictionary
    const tempRecordingsDict = {};
    recordings.forEach((recording) => {
      const dateKey = getDateKey(recording.timestamp._seconds);
      if (!tempRecordingsDict[dateKey]) {
        tempRecordingsDict[dateKey] = [];
      }
      tempRecordingsDict[dateKey].push(recording);
    });

    // Set the state with the populated dictionary
    setFilteredRecordings(tempRecordingsDict);
    setRecordingsDict(tempRecordingsDict);
  }, [recordings]);

  useEffect(() => {
    if (Object.keys(recordingsDict).length > 0) {
      if (!searchName) {
        setFilteredRecordings(recordingsDict);
        return;
      }

      // Filter recordings by patient name
      const filtered = Object.keys(recordingsDict).reduce((acc, date) => {
        const filteredByDate = recordingsDict[date].filter((recording) =>
          recording.patient_name
            .toLowerCase()
            .includes(searchName.toLowerCase())
        );
        if (filteredByDate.length > 0) {
          acc[date] = filteredByDate;
        }
        return acc;
      }, {});

      setFilteredRecordings(filtered);
    }
  }, [searchName, recordings]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenMenuIndex(null);
    }
  };

  const handleRecordClick = (record) => {
    setSelectedRecord(record);
  };

  const toggleMenu = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleBackClick = () => {
    setSelectedDate(null);
  };

  return (
    <div ref={dropdownRef} className="">
      {!selectedDate ? (
        Object.keys(filteredRecordings).map((date, index) => (
          <div
            className="border-b border-customBlack-light text-sm flex items-center justify-between px-2 py-4 cursor-pointer hover:bg-gray-100"
            onClick={() => handleDateSelect(date)}
          >
            <div className="flex flex-col gap-2">
              <p>
                {/* <strong>{record.patient_name}</strong> */}
                {date}
              </p>
              {/* <p className="text-customBlack-text ">
              {new Date(record.timestamp.seconds * 1000).toLocaleString()}
            </p> */}
            </div>
          </div>
        ))
      ) : (
        <RecordingsList
          date={selectedDate}
          recordings={filteredRecordings[selectedDate]}
          onBackClick={handleBackClick}
          handleRecordClick={handleRecordClick}
        />
      )}
    </div>
  );
};

const RecordingsList = ({
  date,
  recordings,
  onBackClick,
  handleRecordClick,
}) => {
  return (
    <div>
      <div className="flex items-center px-2 py-4 justify-between border-b border-customBlack-light">
        {/* <button onClick={onBackClick}>Back</button> */}
        <MdArrowBack onClick={onBackClick} className="cursor-pointer" />

        <h2 className="flex gap-2">
          Recordings for:
          <strong>{date}</strong>
        </h2>
      </div>
      <ul>
        {recordings?.map((recording, index) => (
          <div
            className="border-b border-customBlack-light text-sm flex items-center justify-between px-2 py-4 cursor-pointer hover:bg-gray-100"
            key={index}
            onClick={() => handleRecordClick(recording)}
          >
            <p>Patient Name: {recording.patient_name}</p>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default NotesList;
