// src/analytics.js
import ReactGA from "react-ga";

const initializeAnalytics = () => {
  ReactGA.initialize("G-5R7DCB8TJC");
};

const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export { initializeAnalytics, trackEvent };
