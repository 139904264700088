import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { initializeApp } from "firebase/app";
import "./index.css";

// const firebaseConfig = {
//   apiKey: "AIzaSyCawWPOIP0brGJsLEFXc-r8Z54Lq1H4YXE",
//   authDomain: "hpemr-dcf7b.firebaseapp.com",
//   databaseURL: "https://hpemr-dcf7b-default-rtdb.firebaseio.com",
//   projectId: "hpemr-dcf7b",
//   storageBucket: "hpemr-dcf7b.appspot.com",
//   messagingSenderId: "430135280724",
//   appId: "1:430135280724:web:78ad00994e562420c919c1",
//   measurementId: "G-5R7DCB8TJC",
// };

// initializeApp(firebaseConfig);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
