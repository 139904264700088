import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-4 text-center">
        MD-Voice Privacy Policy
      </h1>
      <p className="text-sm text-gray-500 mb-8 text-center">
        Effective Date: 01-07-2024
      </p>

      <div className="space-y-6">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p className="text-gray-700">
            MD-Voice is committed to protecting the privacy and security of your
            personal information. This Privacy Policy outlines how we collect,
            use, disclose, and safeguard your information when you use our AI
            medical app/web app.
          </p>
        </section>

        {/* Information We Collect */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">
            2. Information We Collect
          </h2>

          <h3 className="text-xl font-semibold mb-2">
            2.1. Personal Information:
          </h3>
          <p className="text-gray-700">
            We may collect personal information from doctors who use our
            service, including:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-700">
            <li>Name</li>
            <li>Contact information (email address, phone number)</li>
            <li>Device information (type of device, operating system)</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            2.2. Patient Information:
          </h3>
          <p className="text-gray-700">
            We collect and process patient information as part of the
            Physician-Patient Dialogue Transcription and SOAP/Consult Note
            Generation process. This information includes:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-700">
            <li>Audio recordings of physician-patient conversations</li>
            <li>Transcripts of these conversations</li>
            <li>Generated SOAP notes and consult notes</li>
            <li>
              Patient demographics (age, gender, etc.) - if provided by the
              doctor
            </li>
          </ul>
        </section>

        {/* How We Use Your Information */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">
            3. How We Use Your Information
          </h2>

          <h3 className="text-xl font-semibold mb-2">
            3.1. Providing Our Services:
          </h3>
          <p className="text-gray-700">
            We use your information to provide and improve our AI medical
            app/web app, including:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-700">
            <li>
              Processing audio recordings and generating transcripts and SOAP
              notes
            </li>
            <li>Providing customer support</li>
            <li>Managing your account</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            3.2. Research and Development:
          </h3>
          <p className="text-gray-700">
            We don’t use any client information for research and development.
          </p>
        </section>

        {/* Data Security */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
          <p className="text-gray-700">
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, or destruction. These measures include:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-700">
            <li>Encryption of data</li>
            <li>Access controls</li>
            <li>Regular security audits</li>
            <li>Employee training on data privacy</li>
          </ul>
        </section>

        {/* Data Sharing */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">5. Data Sharing</h2>
          <p className="text-gray-700">
            We do not share your personal information with third parties for
            marketing purposes. However, we may share your information with:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-700">
            <li>
              Our trusted service providers who assist us in providing our
              services (e.g., cloud storage providers, payment processors)
            </li>
            <li>Law enforcement or regulatory agencies as required by law</li>
          </ul>
        </section>

        {/* Your Rights */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">6. Your Rights</h2>
          <p className="text-gray-700">
            You may have certain rights regarding your personal information,
            such as the right to access, correct, or delete your information. To
            exercise these rights, please contact us at{" "}
            <a
              href="mailto:[email protected]"
              className="text-blue-500 underline"
            >
              [email protected]
            </a>
            .
          </p>
        </section>

        {/* Children's Privacy */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">7. Children's Privacy</h2>
          <p className="text-gray-700">
            Our services are intended for healthcare professionals and not for
            children. We do not knowingly collect personal information from
            children.
          </p>
        </section>

        {/* Changes to This Privacy Policy */}
        <section>
          <h2 className="text-2xl font-semibold mb-2">
            8. Changes to This Privacy Policy
          </h2>
          <p className="text-gray-700">
            We may update this Privacy Policy from time to time. We will notify
            you of any material changes by posting the new Privacy Policy on our
            website.
          </p>
        </section>
      </div>
      <a href="/contact-us" target="_blank">
        Contact Us
      </a>
    </div>
  );
};

export default PrivacyPolicy;
