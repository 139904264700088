import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/LandingPage.css";
import Signup from "./components/Auth/Signup";
import Login from "./components/Auth/Login";
import MainScreen from "./components/Main/MainScreen";
import LandingPage from "./components/Main/LandingPage";
import Navbar from "./components/Global/Navbar";
import UserNavbar from "./components/Global/UserNavbar";
import { initializeAnalytics } from "./analytics";
import StripeContainer from "./components/Main/StripeContainer";
import Success from "./components/Main/Succes";
import Cancel from "./components/Main/Cancel";
import Settings from "./components/Main/Settings";
import PricingPage from "./components/Main/PricingPage";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./components/Auth/ForgotPassword";
import PrivacyPolicy from "./components/Main/PrivacyPolicy";
import DeleteAccount from "./components/Main/DeleteAccount";
import TermConditions from "./components/Main/TermConditions";
import ContactTable from "./components/Main/ContactUsTable";
import LuckyDraw from "./components/Main/LuckyDraw";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  // useEffect(() => {
  //   initializeAnalytics();
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <div className="App font-sans">
      <ToastContainer />

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Navbar />
                <LandingPage />
              </div>
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactTable />} />
          <Route path="/lucky-draw" element={<LuckyDraw />} />
          <Route path="/terms-and-conditions" element={<TermConditions />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />

          <Route
            path="/dashboard"
            element={
              <div>
                <ProtectedRoute>
                  <UserNavbar />
                  <LandingPage />
                </ProtectedRoute>
              </div>
            }
          />
          <Route
            path="/main"
            element={
              <ProtectedRoute>
                <UserNavbar />

                <MainScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <ProtectedRoute>
                <StripeContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <Success />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <UserNavbar />
                <PricingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <UserNavbar />
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cancel"
            element={
              <ProtectedRoute>
                <Cancel />
              </ProtectedRoute>
            }
          />

          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, [navigate]);
  return children;
};

initializeAnalytics();

export default App;
