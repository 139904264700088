import { create } from "zustand";

const useRecordingStore = create((set) => ({
  selectedDate: null,
  searchName: "",

  setSelectedDate: (selectedDate) => set({ selectedDate }),
  setSearchName: (searchName) => set({ searchName }),
}));

export default useRecordingStore;
