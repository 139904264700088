import React from "react";
import AppStore from "../../../assets/appstore.png";
import PlayStore from "../../../assets/playstore.png";
const AppBanners = () => {
  return (
    <div className="w-full md:flex justify-center">
      <div className="bg-customBlue-primary py-8 md:w-[75%] my-4 rounded-lg">
        <h2 className="text-3xl font-bold text-center text-white mb-6">
          Download Now
        </h2>
        <div className="max-w-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:p-2 p-4">
          {/* Column 1: Google Play Store */}
          <a
            href="https://play.google.com/store/apps/details?id=ai.hpemr.mdvoice"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center bg-white rounded-lg p-4">
              <img src={PlayStore} alt="Google Play" className="w-12 h-12" />
              <div className="ml-3">
                <p className="text-xs text-customRed-text">GET IT ON</p>
                <p className="text-2xl font-bold text-gray-900">Google Play</p>
              </div>
            </div>
          </a>
          {/* Column 2: Apple App Store */}
          <a
            href="https://apps.apple.com/us/app/md-voice/id6651860912"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center bg-white rounded-lg p-4">
              <img src={AppStore} alt="App Store" className="w-12 h-12" />
              <div className="ml-3">
                <p className="text-xs text-customRed-text">DOWNLOAD ON</p>
                <p className="text-2xl font-bold text-gray-900">App Store</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppBanners;
