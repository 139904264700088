import React from "react";

export function PrimaryButton({ label, onClick, isDisabled }) {
  return (
    <button
      onClick={onClick}
      className="w-inherit h-fit p-2.5 bg-customBlue-primary text-white border-none rounded-lg cursor-pointer transition duration-300 ease-in-out hover:bg-teal-500"
      disabled={isDisabled}
    >
      {label}
    </button>
  );
}

export function SecondaryButton({ label, onClick }) {
  return (
    <button
      onClick={onClick}
      className="p-2.5 bg-transparent border border-customBlack-lighter rounded-lg cursor-pointer"
    >
      {label}
    </button>
  );
}
