import React from "react";
import Pricing from "./sections/Pricing";
import Features from "./sections/Features";

export default function PricingPage() {
  return (
    <div className="p-2">
      <Pricing />
      <Features />
    </div>
  );
}
