import React from "react";
import "../../styles/Loader.css";

const Loader = ({ showText }) => {
  return (
    <div className="loader">
      <div className="spinner"></div>
      {showText && <p>Processing your audio...</p>}
    </div>
  );
};

export default Loader;
