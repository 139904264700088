import React from "react";

const TermConditions = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-6 text-center">
          MD-Voice Terms and Conditions
        </h1>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            1. Acceptance of Terms
          </h2>
          <p className="mb-4">
            By accessing or using MD-Voice, you agree to be bound by these Terms
            and Conditions. If you do not agree to these terms, please do not
            use MD-Voice.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            2. Service Description
          </h2>
          <p className="mb-4">
            MD-Voice is an AI medical app/web app designed to assist healthcare
            professionals in physician-patient dialogue transcription and
            SOAP/Consult note generation.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            3. User Eligibility
          </h2>
          <p className="mb-4">
            To use MD-Voice, you must be a licensed healthcare professional.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            4. Account Creation
          </h2>
          <p className="mb-4">
            You may be required to create an account to use certain features of
            MD-Voice. You are responsible for maintaining the confidentiality of
            your account credentials.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            5. User Conduct
          </h2>
          <p className="mb-4">
            You agree not to:
            <ul className="list-disc pl-6">
              <li>
                Use MD-Voice in a manner that violates any applicable laws or
                regulations.
              </li>
              <li>
                Use MD-Voice to transmit or store any harmful or illegal
                content.
              </li>
              <li>
                Attempt to gain unauthorized access to MD-Voice or its systems.
              </li>
              <li>
                Use MD-Voice for any commercial purpose without our prior
                written consent.
              </li>
            </ul>
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            6. Intellectual Property
          </h2>
          <p className="mb-4">
            MD-Voice and its content, including but not limited to the software,
            technology, and trademarks, are the property of MD voice Inc. or its
            licensors. You may not reproduce, modify, distribute, or otherwise
            use any part of MD-Voice without our prior written consent.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            7. Disclaimer of Warranties
          </h2>
          <p className="mb-4">
            MD-Voice is provided on an "as is" and "as available" basis without
            warranties of any kind. We do not warrant that MD-Voice will be
            error-free, uninterrupted, or secure.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            8. Limitation of Liability
          </h2>
          <p className="mb-4">
            In no event shall MD voice Inc. be liable for any indirect,
            incidental, special, or consequential damages arising out of or in
            connection with your use of MD-Voice, even if we have been advised
            of the possibility of such damages.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            9. Indemnification
          </h2>
          <p className="mb-4">
            You agree to indemnify and hold MD voice Inc. harmless from any
            claims, liabilities, damages, or losses arising out of or in
            connection with your use of MD-Voice or your violation of these
            Terms and Conditions.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            10. Termination
          </h2>
          <p className="mb-4">
            We may terminate your access to MD-Voice at any time for any reason.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            11. Governing Law
          </h2>
          <p className="mb-4">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of Canada and United States.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            12. Changes to Terms
          </h2>
          <p className="mb-4">
            We may update these Terms and Conditions from time to time. We will
            notify you of any material changes by posting the updated terms on
            our website.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermConditions;
