// PlanSelection.js
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import useUserStore from "../../utils/userStore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeContainer = () => {
  const [plan, setPlan] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    const response = await axios.post(
      `${process.env.REACT_APP_BE_DOMAIN}/stripe/create-checkout-session`,
      {
        userId: currentUser.user_id,
        priceId: plan,
        promotionCode: "ABC",
        headers: {
          "Content-Type": "multipart/form-data",
          // 'Authorization': `Bearer ${token}`
        },
      }
    );

    console.log("🚀 ~ handleCheckout ~ response:", response);
    const session = await response.data;

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // Display error to your customer
      console.error(result.error.message);
    }
  };

  return (
    <div>
      <select value={plan} onChange={(e) => setPlan(e.target.value)} required>
        <option value="" disabled>
          Select your plan
        </option>
        <option value={process.env.REACT_APP_PRICE_50}>Monthly - $50</option>
        <option value={process.env.REACT_APP_PRICE_75}>Monthly - $75</option>
      </select>
      <button onClick={handleCheckout} disabled={!plan}>
        Subscribe
      </button>
    </div>
  );
};

export default StripeContainer;
