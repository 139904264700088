// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
  PhoneAuthProvider,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, // Add this line
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const trackEvent = (category, action, label) => {
  logEvent(analytics, category, {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
};

const auth = getAuth(app);
const recaptchaVerifier = () => {
  return new RecaptchaVerifier(auth, "recaptcha-container", {
    size: "invisible",
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      console.log("reCAPTCHA solved");
    },
    "expired-callback": () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      console.log("reCAPTCHA expired");
    },
  });
};

export {
  auth,
  recaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
  PhoneAuthProvider,
  trackEvent,
};
