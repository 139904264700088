import React, { useState } from "react";
import RecordingArea from "./RecordingArea";
import History from "./History";
import "../../styles/MainScreen.css";

const MainScreen = () => {
  const [activeTab, setActiveTab] = useState("recording");

  const renderContent = () => {
    if (activeTab === "recording") {
      return <RecordingArea />;
    } else {
      return <History />;
    }
  };

  return (
    <div
    // className="main-page"
    >
      <div className="tabs">
        {/* <button
          className={activeTab === "recording" ? "active" : ""}
          onClick={() => setActiveTab("recording")}
        >
          Recording Area
        </button>
        <button
          className={activeTab === "history" ? "active" : ""}
          onClick={() => setActiveTab("history")}
        >
          History
        </button> */}
      </div>
      <div
      // className="tab-content"
      >
        {renderContent()}
      </div>
    </div>
  );
};

export default MainScreen;
