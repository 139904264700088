import React from "react";

export default function Layout({ children, img }) {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>{children}</div>
        <div className="hidden md:flex ">
          <img src={img} alt="Placeholder" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
}
