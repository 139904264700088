import axios from "axios";
import React, { useState } from "react";
import useUserStore from "../../utils/userStore";
import { toast, ToastContainer } from "react-toastify";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { signInWithCredential } from "firebase/auth";
import {
  auth,
  recaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
} from "../../firebase";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import "../../styles/Auth.css";

export default function DeleteAccount() {
  const currentUser = useUserStore((state) => state.currentUser);
  console.log("🚀 ~ DeleteAccount ~ currentUser:", currentUser);
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const setupRecaptcha = () => {
    try {
      window.recaptchaVerifier = recaptchaVerifier();
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } catch (error) {
      console.error("Error setting up reCAPTCHA:", error);
    }
  };

  const sendVerificationCode = async (e) => {
    e.preventDefault();
    // if (!isValidPhoneNumber(phoneNumber)) {
    //   toast.error("Invalid Phone number");
    //   return;
    // }

    setupRecaptcha();
    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        currentUser.phone_number,
        window.recaptchaVerifier
      );

      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const userCredential = await signInWithCredential(auth, credential);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      const response = await fetch(
        `${process.env.REACT_APP_BE_DOMAIN}/user/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("User verified successfully:", data);
        setIsVerified(true);
      } else {
        console.error("Failed to verify user");
      }
    } catch (error) {
      console.error("Error verifying code:", error);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      navigate("/login");

      console.log("Logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BE_DOMAIN}/user/delete`,
        {
          user_id: currentUser.user_id,
          is_active: false,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );

      if (response.data) {
        toast.success("Account has been deleted successfully");
        handleLogout();
      }
    } catch (error) {
      console.error("Error updating data", error);
      toast.error("Error updating data");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="auth-form">
        <div className="flex text-center items-center justify-center  flex-col gap-2">
          <p className="text-3xl">
            Are you sure you want to delete your account?
          </p>
          <p className="text-xl">
            Once this action is performed it cannot be undone
          </p>

          <div className="flex flex-col gap-2">
            <button onClick={handleDeleteAccount} disabled={!isVerified}>
              Delete
            </button>
            <button onClick={sendVerificationCode}>
              Send Verification Code
            </button>
            <div id="recaptcha-container"></div>
            <>
              {verificationId && (
                <div>
                  <input
                    type="text"
                    placeholder="Verification Code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                  <button onClick={verifyCode}>Verify Code</button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
