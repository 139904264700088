// Success.js
import React from "react";

const Success = () => (
  <div>
    <h1>Subscription Successful!</h1>
    <p>Thank you for subscribing. Your payment was successful.</p>
  </div>
);

export default Success;
