import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "../../styles/History.css";

const History = () => {
  const [recordings, setRecordings] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    const fetchRecordings = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
      const q = query(collection(db, "audios"), orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecordings(data.filter((record) => record.user_id === user.uid));
    };

    fetchRecordings();
  }, []);

  const handleRecordClick = (record) => {
    setSelectedRecord(record.id === selectedRecord?.id ? null : record);
  };

  return (
    <div className="history">
      <h2>History</h2>
      <ul>
        {recordings.map((record) => (
          <React.Fragment key={record.id}>
            <li onClick={() => handleRecordClick(record)}>
              <p>
                <strong>{record.patient_name}</strong>
              </p>
              <p>
                {new Date(record.timestamp.seconds * 1000).toLocaleString()}
              </p>
            </li>
            {selectedRecord?.id === record.id && (
              <div className="record-details">
                <h4>Transcript</h4>
                <p>{record.transcript}</p>
                <h4>SOAP Note</h4>
                <p>{record.soap_note}</p>
                <h4>Recording</h4>
                <p>
                  <a
                    href={record.audio_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Listen
                  </a>
                </p>
              </div>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default History;
