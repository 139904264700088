import React from "react";

const Input = ({
  type,
  placeholder,
  label,
  value,
  name,
  handleOnChange,
  isRequired,
  props,
}) => {
  return (
    <div className="text-left">
      <p>{label}</p>
      <input
        className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg"
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        required={isRequired}
        {...props}
      />
    </div>
  );
};

export default Input;
