// Cancel.js
import React from "react";

const Cancel = () => (
  <div>
    <h1>Subscription Cancelled</h1>
    <p>Your subscription was cancelled. Please try again.</p>
  </div>
);

export default Cancel;
