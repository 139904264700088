import { useState } from "react";
import axios from "axios";
import "../../styles/Auth.css";

const ApplyCoupon = ({ userId }) => {
  const [couponCode, setCouponCode] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const applyCoupon = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/stripe/apply-coupon`,
        {
          userId,
          couponCode,
        }
      );
      setStatusMessage("Coupon applied successfully!");
    } catch (error) {
      setStatusMessage("Failed to apply coupon. Try again.");
    }
  };

  return (
    <div className="">
      <input
        type="text"
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        placeholder="Enter Coupon Code"
      />
      <button onClick={applyCoupon}>Apply Coupon</button>
      <p>{statusMessage}</p>
    </div>
  );
};

export default ApplyCoupon;
