import React from "react";
import DrLimane from "../../../assets/dr-lamine.jpg";
import Yasser from "../../../assets/yasser.png";
import Limam from "../../../assets/Limam.jpg";
import Amin from "../../../assets/Amin.png";
import DrSalah from "../../../assets/Salah-Ali.jpg";
import DrRifai from "../../../assets/Dr_-Omar-Rafai.webp";
import DrSibai from "../../../assets/Sibai.png";
import Zack from "../../../assets/Zack.jpg";
import AliZahid from "../../../assets/ali-zahid.JPG";
import Rhygan from "../../../assets/rhygan.jpg";
import Elena from "../../../assets/elena.jpg";

const teamMembers = [
  {
    name: "Mohamed Limam",
    role: "CEO",
    image: Limam,
    experience: "VP of Operations @ Aqua Solutions Canada",
  },
  // {
  //   name: "Dr. Mohamed Lamine",
  //   role: "Chairman/Co-Founder",
  //   image: DrLimane,
  //   experience: "Owner / Director @ Health Plus group",
  // },
  {
    name: "Yasser Seoud",
    role: "CSO",
    image: Yasser,
    experience:
      "Managing Partner @ aims Director Marketing and Corporate Communication @ MENA ENTERPRISES FZE",
  },
  // {
  //   name: "Dr. Salah Ali",
  //   role: "VP Quality Assurance/Co-Founder",
  //   image: DrSalah,
  //   experience: "Medical Director / Family Physician @ MiClinic",
  // },
  {
    name: "Amin El-Naggar",
    role: "VP of Technology",
    image: Amin,
    experience: "Senior Director of Engineering @ Atlas OS",
  },
  {
    name: "Rhygan Farrington",
    role: "Sales Executive",
    image: Rhygan,
  },
  {
    name: "Ali Zahid Raja",
    role: "AI & Machine Learning Visionary",
    image: AliZahid,
    // experience: "Cultivating company culture and talent.",
  },
  {
    name: "Elena Khramova",
    role: "Customer Acquisition Manager",
    image: Elena,
    // experience: "Full-stack development guru.",
  },
  {
    name: "Dr. M. Zack",
    role: "Marketing Director",
    image: Zack,
    // experience: "Full-stack development guru.",
  },

  {
    name: "Dr. H. Sibai",
    role: "Board Advisor",
    image: DrSibai,
    experience:
      "Hematologist Oncologist @ Princess Margaret Cancer Hospital, Toronto Assistant Professor @ Faculty of Medicine, University of Toronto",
  },
  {
    name: " Dr. O. Rifai",
    role: "Board Advisor",
    image: DrRifai,
  },
  // Add more team members as needed
];

const TeamMember = ({ name, role, image, experience }) => (
  <div className="flex flex-col items-center p-4 ">
    <img
      src={image}
      alt={name}
      className="w-32 h-32 rounded-full object-cover mb-4"
    />
    <h3 className="text-xl font-semibold">{name}</h3>
    <p className="text-customRed-text font-medium">{role}</p>
    {experience && (
      <p className="mt-2 text-center text-gray-700 text-sm">{experience}</p>
    )}
  </div>
);

const OurTeam = () => {
  return (
    <div className=" rounded-lg mx-auto px-4 sm:px-6 lg:px-8 py-12  bg-gray-100">
      <h2 className="text-3xl font-extrabold text-center mb-8">Our Team</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
