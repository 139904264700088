import React from "react";
import "../../../styles/Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="flex md:flex-row flex-col items-center justify-between px-2">
        <div>
          <li>
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/terms-and-conditions" target="_blank">
              Terms & Conditions
            </a>
          </li>
        </div>

        <div className="">
          <p>support@mdvoice.ai</p>
          <p>+1 (872) 395-4424</p>
        </div>

        <p>© 2024 MD Voice. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
