import React from "react";
import Input from "../Global/Input";
import { PrimaryButton } from "../Global/Button";
import NotesList from "./NotesList";

export default function RecordingSection({
  handleNameSearch,
  tempSearchName,
  setTempSearchName,
  selectedRecord,
  setSelectedRecord,
  reloadData,
}) {
  return (
    <div className="w-full md:w-[23%] order-2 md:order-1 min-h-screen border-r border-customBlack-light overflow-y-auto">
      <div className="">
        <div className="border-b border-customBlack-light p-2">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold">Recordings</p>
          </div>
          <div className="flex gap-2 flex-wrap items-center ">
            <div className="w-[70%]">
              <Input
                value={tempSearchName}
                type={"text"}
                handleOnChange={(e) => setTempSearchName(e.target.value)}
                placeholder={"Search name"}
                isRequired={false}
              />
            </div>

            <div>
              <PrimaryButton label={"Search"} onClick={handleNameSearch} />
            </div>
          </div>
        </div>

        <NotesList
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          reloadData={reloadData}
        />
      </div>
    </div>
  );
}
