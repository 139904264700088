import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Navbar.css";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  return (
    <header>
      <nav className="md:flex items-center justify-between p-4 border-b border-customGrey-primary">
        {/* Logo */}
        <Link to="/">
          <img src={Logo} alt="Company Logo" className="h-12" />
        </Link>

        <div className="flex flex-col gap-2">
          <div className="flex flex-col md:flex-row  md:space-x-4 space-y-2 md:space-y-0">
            <Link
              to="/lucky-draw"
              className="btn-primary border border-customBlack-primary font-semibold"
            >
              Win an iPhone 16!
            </Link>
            <Link
              to="/login"
              className="btn-primary border border-customBlack-primary font-semibold"
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="btn-secondary border border-customBlue-secondary font-semibold"
            >
              Try It - It's Free!
            </Link>
          </div>
          <p className="text-xs md:text-right text-left">
            No credit card required
          </p>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
