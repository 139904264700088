import React from "react";
import "../../../styles/Features.css";
import Feature1 from "../../../assets/f1.png";
import Feature2 from "../../../assets/f2.png";
import Feature3 from "../../../assets/f3.png";
import Feature4 from "../../../assets/f4.png";
import Feature5 from "../../../assets/f5.png";
import Feature6 from "../../../assets/f6.png";
import Video1 from "../../../assets/video1.mp4";
import Video2 from "../../../assets/video2.mp4";

const Features = () => {
  const features = [
    {
      image: Feature2,
      heading: "Automated SOAP Notes",
      description:
        "Generate comprehensive SOAP notes instantly, allowing you to focus more on patient care and less on paperwork.",
    },
    {
      image: Feature1,
      heading: "Mobile Apps",
      description:
        "Provided both IOS and Android Apps for maximum convenience.",
    },
    {
      image: Feature2,
      heading: "Multiple Languages",
      description:
        "Have the option of choosing from multiple different languages, whichever suits your need best.",
    },
    {
      image: Feature4,
      heading: "Unlimited Recordings",
      description:
        "Enjoy the freedom to record as many conversations as you need without any limits.",
    },
    {
      image: Feature1,
      heading: "Live Transcription",
      description:
        "Capture every detail with real-time transcription of doctor-patient conversations. No more missed information.",
    },
    {
      image: Feature5,
      heading: "Maintain History",
      description:
        "Track and analyze transcription and note-taking efficiency.",
    },
    {
      image: Feature6,
      heading: "Cost Savings",
      description:
        "Benefit from a reduced rate of when multiple doctors join using the same group code.",
    },

    {
      image: Feature3,
      heading: "Hosted in Canada",
      description:
        "End to End encryption. HIPAA Compliant. Ensure the highest level of security and confidentiality.",
    },
  ];
  const featureChunks = [];
  for (let i = 0; i < features.length; i += 4) {
    featureChunks.push(features.slice(i, i + 4));
  }
  return (
    <div className="my-10 flex flex-col items-center gap-4">
      <p className="text-center text-base text-customRed-text font-semibold">
        Features
      </p>
      <p className="text-center text-4xl font-semibold">
        Unleash the Power of MD Voice
      </p>
      <p className="text-center text-xl mt-2 text-customBlack-secondary md:w-[47%]">
        Streamline Your Workflow with Cutting-Edge Transcription and Note-Taking
        Features
      </p>
      {/* <ParentComponent /> */}
      <div className="grid gap-20 mt-10 xl:p-10">
        {/* Videos for mobile and tablet view */}
        <div className="block xl:hidden space-y-4">
          <VideoComponent videoUrl={Video1} />
          <VideoComponent videoUrl={Video2} />
        </div>

        {/* Existing layout for desktop */}
        {featureChunks.map((featureChunk, index) => (
          <div key={index} className="xl:flex w-full items-center">
            {/* Conditionally render Video1 in desktop view when index === 1 */}
            {index === 1 && (
              <div className="hidden xl:block xl:w-1/2">
                <VideoComponent videoUrl={Video1} />
              </div>
            )}

            {/* Render the features */}
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-10 xl:w-1/2 items-center justify-center">
              {featureChunk.map((feature, i) => (
                <Feature key={i} {...feature} />
              ))}
            </div>

            {/* Conditionally render Video2 in desktop view when index === 0 */}
            {index === 0 && (
              <div className="hidden xl:block xl:w-1/2">
                <VideoComponent videoUrl={Video2} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Feature = ({ image, heading, description }) => {
  return (
    <div className="text-center flex flex-col items-center">
      <img src={image} alt={heading} className="mx-auto mb-4 " />
      <h3 className="text-xl font-bold">{heading}</h3>
      <p className="mt-2 md:w-[65%]">{description}</p>
    </div>
  );
};

const FirstComponent = ({ icon, title, description, bulletPoints }) => {
  return (
    <div className="p-4 w-full md:w-1/2">
      {/* Line 1: Small Icon */}
      <div className="mb-2">
        <img src={icon} alt="Icon" />
      </div>
      {/* Line 2: Title */}
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      {/* Line 3: Description */}
      <p className="text-gray-600 mb-4">{description}</p>
      {/* Line 4: Bullet Points with Check Icon */}
      <ul className="list-none">
        {bulletPoints.map((point, index) => (
          <li key={index} className="flex items-start mb-2">
            <svg
              className="w-4 h-4 text-red-500 mr-2 mt-1"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              {/* Check Icon */}
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span>{point}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const VideoComponent = ({ videoUrl }) => {
  return (
    <div className=" flex justify-center items-center">
      <video controls playsInline autoPlay muted className="w-full h-auto">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const ParentComponent = () => {
  const rows = [
    {
      firstComponentData: {
        icon: Feature1,
        title: "Real-Time Transcription",
        description:
          "Instantly capture and transcribe doctor-patient conversations in real-time, ensuring you never miss a detail.",
        bulletPoints: [
          "Instant, accurate voice-to-text transcription during consultations.",
          "Displays live transcript for immediate review.",
          "Saves time by eliminating manual note-taking.",
        ],
      },
      secondComponentData: {
        videoUrl: "/path/to/video1.mp4",
      },
      inverted: false,
    },
    {
      firstComponentData: {
        icon: Feature2,
        title: "Automated SOAP Notes",
        description:
          "Convert transcriptions into detailed SOAP notes with just one click, simplifying documentation and reducing administrative tasks.",
        bulletPoints: [
          "Instantly generate structured notes (Subjective, Objective, Assessment, Plan).",
          "Reduces manual workload, allowing you to focus on patient care.",
          "Ensures uniform, professional documentation.",
        ],
      },
      secondComponentData: {
        videoUrl: "/path/to/video2.mp4",
      },
      inverted: true,
    },
    {
      firstComponentData: {
        icon: Feature4,
        title: "Unlimited Recordings",
        description:
          "Record as many conversations as needed without restrictions, ensuring you have the flexibility to capture all patient interactions.",
        bulletPoints: [
          "No cap on the number of recordings you can make.",
          "Perfect for busy practices with high patient volume.",
          "Store and access all conversations securely for future reference.",
        ],
      },
      secondComponentData: {
        videoUrl: "/path/to/video3.mp4",
      },
      inverted: false,
    },
  ];

  return (
    <div className="flex flex-col space-y-8">
      {rows.map((row, index) => (
        <div
          key={index}
          className={`flex flex-col md:flex-row ${
            row.inverted ? "md:flex-row-reverse" : ""
          } items-center`}
        >
          <FirstComponent {...row.firstComponentData} />
          <VideoComponent {...row.secondComponentData} />
        </div>
      ))}
    </div>
  );
};

export default Features;
