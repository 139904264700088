import React, { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithCredential,
} from "firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../styles/Auth.css";
import SignUpImg from "../../assets/signup.jpg";
import Layout from "./Layout";
import Input from "../Global/Input";
import Loader from "../Main/Loader";
import { trackEvent } from "../../analytics";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  auth,
  recaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
} from "../../firebase";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordInput from "../Global/PasswordInput";
import { FaRegCircleCheck } from "react-icons/fa6";

const signUpSchema = Yup.object({
  name: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
  phone: Yup.string().required("Required"),
  email: Yup.string(),
  plan: Yup.string(),
});

const SignupForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const location = useLocation();
  const { plan } = location?.state || {};
  console.log("🚀 ~ SignupForm ~ plan:", plan);

  const formik = useFormik({
    initialValues: {
      name: "",
      password: "",
      email: "",
      phone: "",
      plan: plan ? plan : "0",
    },

    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const auth = getAuth();

      try {
        const credential = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );

        const userCredential = await signInWithCredential(auth, credential);
        const user = userCredential.user;
        const idToken = await user.getIdToken();

        const response = await fetch(
          `${process.env.REACT_APP_BE_DOMAIN}/user/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ idToken }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("User verified successfully:", data);
          setIsVerified(true);

          try {
            const user = auth.currentUser;

            try {
              const hashedPassword = CryptoJS.SHA256(
                values.password
              ).toString();

              const response = await axios.post(
                `${process.env.REACT_APP_BE_DOMAIN}/user`,
                {
                  user_id: user.uid,
                  password: hashedPassword,
                  name: values.name,
                  email: values.email,
                  phone_number: values.phone,
                  plan: values.plan,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    // 'Authorization': `Bearer ${token}`
                  },
                }
              );

              if (response.data) {
                trackEvent("User", "SignUp", "User signed up");
                navigate("/main");
                if (formik.values.plan === "75" || formik.values.plan == "50") {
                  const response = await axios.post(
                    `${process.env.REACT_APP_BE_DOMAIN}/stripe/create-subscription`,
                    {
                      name: values.name,
                      userId: user.uid,
                      headers: {
                        "Content-Type": "multipart/form-data",
                        // 'Authorization': `Bearer ${token}`
                      },
                    }
                  );
                }
              }
            } catch (error) {
              console.error("Error signing up", error);
              toast.error(error.response.data.result);
              setTimeout(() => window.location.reload(), 3000);
            }
          } catch (error) {
            toast.error(error.message);
          }

          setIsLoading(false);
        } else {
          console.error("Failed to verify user");
        }
      } catch (error) {
        console.error("Error verifying code:", error);
      }
    },
  });
  console.log("🚀 ~ SignupForm ~ formik:", formik.values.plan);

  const setupRecaptcha = () => {
    try {
      window.recaptchaVerifier = recaptchaVerifier();
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } catch (error) {
      console.error("Error setting up reCAPTCHA:", error);
    }
  };

  const sendVerificationCode = async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(formik.values.phone)) {
      setIsLoading(false);
      toast.error("Invalid Phone number");
      return;
    }

    setupRecaptcha();

    const phoneNumberString = formik.values.phone;
    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberString,
        window.recaptchaVerifier
      );

      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhoneChange = (val, index) => {
    if (val) {
      formik.setFieldValue("phone", val);
    } else {
      formik.setFieldValue("phone", "");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="auth-container">
          <div className="auth-form md:p-2 p-4">
            <div className=" flex flex-col gap-4 text-left">
              <div>
                <h1 className="text-h1 font-h1  text-customBlack-primary">
                  Sign Up
                </h1>
                <p className="text-p font-p text-customBlack-secondary">
                  Get Started for Free
                </p>
              </div>

              <form onSubmit={formik.handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <Input
                      name="name"
                      label="Name *"
                      type="text"
                      placeholder="Enter your name"
                      props={{ ...formik.getFieldProps("name") }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <p>Phone Number *</p>

                    <PhoneInput
                      international
                      countryCallingCodeEditable={true}
                      defaultCountry="CA"
                      placeholder={"Phone number"}
                      value={formik.values.phone}
                      onChange={handlePhoneChange}
                      style={{
                        borderRadius: "6px",
                        paddingLeft: "10px",
                        width: "inherit",
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <Input
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Enter your email"
                      props={{ ...formik.getFieldProps("email") }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <PasswordInput
                      name="password"
                      label="Password *"
                      placeholder="Enter password"
                      props={{ ...formik.getFieldProps("password") }}
                    />

                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <p>Plan *</p>
                    <select
                      id="language"
                      name="Language"
                      className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
                      {...formik.getFieldProps("plan")}
                    >
                      <option value="0">Free Plan</option>
                      <option value="75">Standard Plan</option>
                      <option value="50">Promotion Plan</option>
                    </select>
                  </div>
                </div>
                <button onClick={sendVerificationCode}>
                  Send Verification Code
                </button>
                <div id="recaptcha-container"></div>

                {verificationId && (
                  <div className="flex gap-4 items-center justify-between">
                    <input
                      type="text"
                      placeholder="Verification Code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    {isVerified ? (
                      <div className="flex gap-2 items-center w-[60%]">
                        <FaRegCircleCheck />

                        <p>Phone number verified</p>
                      </div>
                    ) : (
                      <button type="submit">
                        Verify Code & Create Account
                      </button>
                    )}
                  </div>
                )}

                <div className="text-center">
                  <div className="text-sm text-left my-3">
                    <p>
                      By Signing up you agree to the{" "}
                      <Link
                        to="/terms-and-conditions" // Use the correct path to your terms page
                        className="text-blue-600 hover:text-blue-500 underline"
                        target="_blank" // This will open in a new tab
                        rel="noopener noreferrer" // For security reasons
                      >
                        Terms & Conditions
                      </Link>
                    </p>
                  </div>
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

const Signup = () => {
  return <Layout children={<SignupForm />} img={SignUpImg} />;
};

export default Signup;
